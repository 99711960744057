<template>

	<!-- Layout Footer -->
	<a-layout-footer>

		<!-- Footer Navigation Menu -->
		<a-menu mode="horizontal">
			<a-menu-item @click="openLink('opmk')">Company </a-menu-item>
			<a-menu-item @click="openLink('opmk')">About Us</a-menu-item>
			<a-menu-item @click="openLink('opmk')">Products</a-menu-item>
		</a-menu>
		<!-- / Footer Navigation Menu -->
		
		<!-- Copyright Notice -->
		<p class="copyright">
			Copyright © 2022 <a href="https://www.optimalpros.com">Optimal Pros</a>.
		</p>
		<!-- / Copyright Notice -->

	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
		methods: {
			openLink(link) {
				link == 'opmk' ? window.open('https://www.optimalpros.com', '_blank') : window.open('https://www.optimalpros.com/services', '_blank');
			}
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>