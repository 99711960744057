import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/functions'
let config = {
  apiKey: "AIzaSyAfVDHoEz9gFUpwrCNT8IDa0Spjk1yVwpg",
  authDomain: "lisa-funnel.firebaseapp.com",
  projectId: "lisa-funnel",
  storageBucket: "lisa-funnel.appspot.com",
  messagingSenderId: "841091910577",
  appId: "1:841091910577:web:7274e6056363842ef50b90",
  measurementId: "G-T8541W8E1T"
  };

  export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
  export const db =  !firebase.apps.length ? firebase.initializeApp(config) : firebase.firestore();
  export const database = !firebase.apps.length ? firebase.initializeApp(config) : firebase.database();
  export const firestorage =  !firebase.apps.length ? firebase.initializeApp(config) : firebase.storage();
  export const firestore = !firebase.apps.length ? firebase.initializeApp(config) : firebase.firestore();
  export const functions = !firebase.apps.length ? firebase.initializeApp(config) : firebase.functions();
  export const googleProvider = new firebase.auth.GoogleAuthProvider();