import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "@/config/db.js";
Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/paused",
    name: "Account Paused",
    meta: { forPublic: true },
    component: () => import("../views/Paused.vue"),
  },
  {
    // will match everything
    path: "/invalid",
    name: "Invlaid Link",
    meta: {
      forPublic: true,
    },
    component: () => import("../views/Invalid.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/layout",
    name: "Layout",
    layout: "dashboard",
    component: () => import("../views/Layout.vue"),
  },
  {
    path: "/analytics",
    name: "Analytics",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Analytics.vue"),
  },
  {
    path: "/locations",
    name: "Business Locations",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Locations.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Campaigns.vue"),
  },
  {
    path: "/managed-campaigns",
    name: "Managed Campaigns",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/ManagedCampaigns.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    layout: "dashboard",
    meta: {
      layoutClass: "layout-profile",
      requiresAuth: true,
    },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/send-now",
    name: "Single Send",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/SingleSend.vue"),
  },
  {
    path: "/sms",
    name: "sms",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/SMS.vue"),
  },
  {
    path: "/teams",
    name: "Team Managemnent",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Teams/Manage.vue"),
  },
  {
    path: "/admin/create",
    name: "Onboarding",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Admin/CreateAccount.vue"),
  },
  {
    path: "/admin/campaigns/:uid",
    name: "Admin Managed Campaigns",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Admin/ManageCampaigns.vue"),
  },
  {
    path: "/admin/users",
    name: "All Users",
    layout: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Admin/Users.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign In",
    meta: {
      forPublic: true,
    },
    component: () => import("../views/Sign-In.vue"),
  },
  {
    path: "/teams/sign-in",
    name: "Teams Sign In",
    meta: {
      forPublic: true,
    },
    component: () => import("../views/Teams/Sign-In.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    meta: {
      layoutClass: "layout-sign-up",
      forPublic: true,
    },
    component: () => import("../views/Sign-Up.vue"),
  },
  {
    path: "/r/:id/:business/:rate/:threshold",
    name: "Review Capture",
    meta: {
      layoutClass: "layout-empty",
      forPublic: true,
    },
    component: () => import("../views/Redirect.vue"),
  },
  {
    path: "/thankyou",
    name: "Thank You",
    meta: {
      layoutClass: "layout-empty",
      forPublic: true,
    },
    component: () => import("../views/Reviews/ThankYou.vue"),
  },
  {
    path: "/share/:owner/:location",
    name: "Share",
    meta: {
      layoutClass: "layout-empty",
      forPublic: true,
    },
    component: () => import("../views/Reviews/DirectShare.vue"),
  },
  {
    path: "/feedback/:id/:business/:rate",
    name: "Feedback Capture",
    meta: {
      layoutClass: "layout-empty",
      forPublic: true,
    },
    component: () => import("../views/Reviews/feedback.vue"),
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

//router Guards
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const forPublic = to.matched.some((record) => record.meta.forPublic);
  if (to.meta.title) document.title = to.meta.title;
  if (requiresAuth && !currentUser) next("/sign-in");
  else if (!requiresAuth && currentUser && !forPublic) next("/dashboard");
  else next();
});

export default router;
