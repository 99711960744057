<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
	<div>
		<!-- Dashboard Layout -->
		<a-layout class="layout-dashboard" id="layout-dashboard"
			:class="[navbarFixed ? 'navbar-fixed' : '', !sidebarCollapsed ? 'has-sidebar' : '', layoutClass]">

			<!-- Main Sidebar -->
			<DashboardSidebar :sidebarCollapsed="sidebarCollapsed" :sidebarColor="sidebarColor"
				:sidebarTheme="sidebarTheme" @toggleSidebar="toggleSidebar"></DashboardSidebar>
			<!-- / Main Sidebar -->

			<!-- Layout Content -->
			<a-layout>

				<!-- Layout Header's Conditionally Fixed Wrapper -->
				<DashboardHeader :sidebarCollapsed="sidebarCollapsed" :navbarFixed="navbarFixed"
					@toggleSettingsDrawer="toggleSettingsDrawer" @toggleSidebar="toggleSidebar"></DashboardHeader>
				<!-- / Layout Header's Conditionally Fixed Wrapper -->

				<!-- Page Content -->
				<a-layout-content>
					<router-view />
				</a-layout-content>
				<!-- / Page Content -->

				<!-- Layout Footer -->
				<DashboardFooter></DashboardFooter>
				<!-- / Layout Footer -->

				<!-- Sidebar Overlay -->
				<div class="sidebar-overlay" @click="sidebarCollapsed = true" v-show="!sidebarCollapsed"></div>
				<!-- / Sidebar Overlay -->
				<a-back-top />
			</a-layout>
			<!-- / Layout Content -->
		</a-layout>
		<!-- / Dashboard Layout -->

	</div>
</template>

<script>
import firebase from '@/config/db.js';
import DashboardSidebar from '../components/Sidebars/DashboardSidebar';
import DashboardHeader from '../components/Headers/DashboardHeader';
import DashboardFooter from '../components/Footers/DashboardFooter';
export default ({
	components: {
		DashboardSidebar,
		DashboardHeader,
		DashboardFooter,
	},
	data() {
		return {
			// Sidebar collapsed status.
			sidebarCollapsed: false,

			// Main sidebar color.
			sidebarColor: "primary",

			// Main sidebar theme : light, white, dark.
			sidebarTheme: "light",

			// Header fixed status.
			navbarFixed: false,

			// Settings drawer visiblility status.
			showSettingsDrawer: false,
		}
	},
	methods: {
		toggleSidebar(value) {
			this.sidebarCollapsed = value;
		},
		toggleSettingsDrawer(value) {
			this.showSettingsDrawer = value;
		},
		toggleNavbarPosition(value) {
			this.navbarFixed = value;
		},
		updateSidebarTheme(value) {
			this.sidebarTheme = value;
		},
		updateSidebarColor(value) {
			this.sidebarColor = value;
		},
		getUserMeta(){
			if (localStorage.getItem('reviews_color')) {
				this.sidebarColor = localStorage.getItem('reviews_color');
			}
			else {
				this.lookUpUser();
			}
		},
		lookUpUser() {
			// set local storage	
			var user = firebase.auth().currentUser;
			var uid = user.uid;
			var that = this;
			firebase
				.firestore()
				.collection('users')
				.doc(uid)
				.get()
				.then(function (doc) {
					if (doc.exists) {
						if (doc.data().image == "statefarm") {
							that.sidebarColor = "danger";
							localStorage.setItem('reviews_color', "danger");

						}
						if (doc.data().image == "allstate") {
							that.sidebarColor = "primary";
							localStorage.setItem('reviews_color', "primary");

						}
						if (doc.data().image == "generic") {
							that.sidebarColor = "light";
							localStorage.setItem('reviews_color', "light");
						}
					} else {
						console.log("No such document!");
					}
				}).catch(function (error) {
					console.log("Error getting document:", error);
				});
		},
		isAccountPaused(){
			var user = firebase.auth().currentUser;
			var uid = user.uid;
			var that = this;
			firebase
				.firestore()
				.collection('users')
				.doc(uid)
				.get()
				.then(function (doc) {
					if (doc.exists) {
						if (doc.data().status == "paused") {
							that.$router.push('/paused');
						}
					} else {
						console.log("No such document!");
					}
				}).catch(function (error) {
					console.log("Error getting document:", error);
				});
		}
	},
	computed: {
		// Sets layout's element's class based on route's meta data.
		layoutClass() {
			return this.$route.meta.layoutClass;
		}
	},
	mounted() {
		this.getUserMeta();
		this.isAccountPaused();
	},
})

</script>
