<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand"><img src="/images/logo-ct-black.png" alt="" /></div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline" id="sidbarMenu">
      <a-menu-item v-if="sidebarLinks.dashboard.enabled">
        <router-link to="/dashboard">
          <span class="icon">
            <i class="fa fa-table-columns"></i>
          </span>
          <span class="label">Dashboard</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.sendNow.enabled">
        <router-link to="/send-now">
          <span class="icon">
            <i class="fa fa-send"></i>
          </span>
          <span class="label">Send Now</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.campaigns.enabled">
        <router-link to="/campaigns">
          <span class="icon">
            <i class="fa fa-boxes"></i>
          </span>
          <span class="label">Campaigns</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.managed.enabled">
        <router-link to="/managed-campaigns">
          <span class="icon">
            <i class="fa fa-boxes"></i>
          </span>
          <span class="label">Managed Campaigns</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.contacts.enabled">
        <router-link to="/contacts">
          <span class="icon">
            <i class="fa fa-address-book"></i>
          </span>
          <span class="label">Contacts</span>
        </router-link>
      </a-menu-item>

      <a-menu-item v-if="sidebarLinks.sms.enabled">
        <router-link to="/sms">
          <span class="icon">
            <i class="fa fa-sms"></i>
          </span>
          <span class="label">SMS</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.analytics.enabled">
        <router-link to="/analytics">
          <span class="icon">
            <i class="fa fa-chart-line"></i>
          </span>
          <span class="label">Analytics</span>
        </router-link>
      </a-menu-item>
      <a-menu-item class="menu-item-header"> Account Pages </a-menu-item>
      <a-menu-item v-if="sidebarLinks.profile.enabled">
        <router-link to="/profile">
          <span class="icon">
            <i class="fa fa-pencil"></i>
          </span>
          <span class="label">Profile</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.locations.enabled">
        <router-link to="/locations">
          <span class="icon">
            <i class="fa fa-home"></i>
          </span>
          <span class="label">Locations</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.teams.enabled">
        <router-link to="/teams">
          <span class="icon">
            <i class="fa fa-people-group"></i>
          </span>
          <span class="label">Teams</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.admin.enabled">
        <router-link to="/admin/users">
          <span class="icon">
            <i class="fa fa-lock"></i>
          </span>
          <span class="label">Admin</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="sidebarLinks.signOut.enabled">
        <router-link to="/sign-in">
          <span class="icon">
            <i class="fa fa-arrow-right-from-bracket"></i>
          </span>
          <span class="label">Sign Out</span>
        </router-link>
      </a-menu-item>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import firebase from "@/config/db.js";
const environmentStatus = process.env.NODE_ENV || "development";
export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },
    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      isProduction: environmentStatus === "production",
      sidebarLinks: {
        dashboard: {
          enabled: true,
        },
        analytics: {
          enabled: false,
        },
        contacts: {
          enabled: true,
        },
        managed: {
          enabled: false,
        },
        campaigns: {
          enabled: true,
        },
        sendNow: {
          enabled: true,
        },
        sms: {
          enabled: false,
        },
        profile: {
          enabled: false,
        },
        locations: {
          enabled: true,
        },
        teams: {
          enabled: false,
        },
        signOut: {
          enabled: true,
        },
        admin: {
          enabled: false,
        },
      },
      // sidebarCollapsedModel: this.sidebarCollapsed,
    };
  },
  methods: {
    logout() {
      firebase.auth().signOut();
      //send to login
      this.$router.push("/sign-in");
    },
    isAdmin() {
      firebase
        .firestore()
        .collection("admins")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.sidebarLinks.admin.enabled = true;
          } else {
            this.sidebarLinks.admin.enabled = false;
          }
        });
    },
    isSMSEnabled() {
      const user = firebase.auth().currentUser;
      const that = this;
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            that.sidebarLinks.sms.enabled = doc.data().sms_access;
          } else {
            that.sidebarLinks.sms.enabled = false;
          }
        });
    },
    isManagedEnabled() {
      const user = firebase.auth().currentUser;
      const that = this;
      firebase
        .firestore()
        .collection("review_funnel_data")
        .doc("funnel")
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            that.sidebarLinks.managed.enabled = doc.data().managed_campaigns;
          } else {
            that.sidebarLinks.managed.enabled = false;
          }
        });
    },
  },
  mounted() {
    this.isAdmin();
    this.isSMSEnabled();
    this.isManagedEnabled();
  },
};
</script>
<style scoped>
.router-link-active > .icon {
  color: #fff !important;
  padding-right: 5px;
}
</style>