<template>
  <!-- Layout Footer -->
  <a-layout-footer>
    <a-row type="flex">
      <a-col :span="24" :md="12">
        <!-- Footer Copyright Notice -->
        <p class="copyright">
          Version: {{ version }} | © 2022, made with
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
              fill="#111827"
            />
          </svg>
          by <a href="https://www.optimalpros.com">OptimalPros</a>.
        </p>
        <!-- / Footer Copyright Notice -->
        <a-modal
          v-model="serviceModal"
          title="Optimal Services"
          @ok="handleOk"
          ok-text="Close"
          cancel-text="Full Page"
          @cancel="linkHandle('https://status.optimalpros.com/')"
        >
          <iframe
            src="https://status.optimalpros.com/?embed=true"
            style="height: 60vh; width: 100%; border: 0; padding: 0; margin: 0"
            frameborder="0"
          ></iframe>
        </a-modal>
      </a-col>
      <a-col :span="24" :md="12" class="footer-control">
        <!-- Footer Navigation Menu -->
        <a-menu mode="horizontal">
          <a-menu-item @click="toggleServiceModal()">Status</a-menu-item>
          <a-menu-item
            @click="linkHandle('https://www.optimalpros.com/services')"
            >Services</a-menu-item
          >
          <a-menu-item
            @click="linkHandle('https://www.optimalpros.com/google-placement')"
            >Google Placement
          </a-menu-item>
          <a-menu-item @click="toggleMode()">{{ viewMode }}</a-menu-item>
          <a-menu-item @click="userDataCollect()">Debug</a-menu-item>
        </a-menu>
        <!-- / Footer Navigation Menu -->
      </a-col>
    </a-row>
    <a-drawer
      width="640"
      placement="right"
      :closable="false"
      :visible="nerdStats"
      @close="onClose"
    >
      <p :style="[pStyle, pStyle2]">User Information</p>
      <a-list size="small" bordered>
        <a-list-item><b>UID:</b> {{ userDataDrawer.uid }}</a-list-item>
        <a-list-item><b>Email:</b> {{ userDataDrawer.email }}</a-list-item>
        <a-list-item><b>App Version:</b> {{ version }}</a-list-item>
        <a-list-item
          ><b>Admin Status:</b>
          <a-tag color="blue">{{ userDataDrawer.admin }}</a-tag>
        </a-list-item>
        <a-list-item
          ><b>Team Status:</b>
          <a-tag color="blue">{{ userDataDrawer.team_member }}</a-tag>
        </a-list-item>
        <a-list-item
          ><b>Team Name:</b> {{ userDataDrawer.team_name }}</a-list-item
        >
        <a-list-item><b>Team ID:</b> {{ userDataDrawer.team_id }}</a-list-item>
        <a-list-item
          ><b>User Type:</b> {{ userDataDrawer.user_type }}</a-list-item
        >
      </a-list>
      <p :style="[pStyle, pStyle2]">Device Information</p>
      <a-list size="small" bordered>
        <a-list-item><b>Device:</b> {{ whatDevice() }}</a-list-item>
        <a-list-item><b>Browser:</b> {{ whatBrowser() }}</a-list-item>
        <a-list-item><b>OS:</b> {{ whatOS() }}</a-list-item>
        <a-list-item><b>IP Address:</b> {{ ip }}</a-list-item>
        <a-list-item><b>Location:</b> {{ geoLocation }}</a-list-item>
        <a-list-item><b>Timezone:</b> {{ whatTimeZone() }}</a-list-item>
      </a-list>
      <p :style="[pStyle, pStyle2]">Business Locations</p>
      <div v-for="item in userDataDrawer.locations" v-bind:key="item.place_id">
        <a-list size="small" bordered>
          <a-list-item><b>Business:</b> {{ item.name }}</a-list-item>
          <a-list-item
            ><b>Place ID:</b> {{ item.place_id }}
            <a
              :href="`https://search.google.com/local/writereview?placeid=${item.place_id}`"
              target="_blank"
              >View On Google</a
            >
          </a-list-item>
          <a-list-item><b>Street:</b> {{ item.street }}</a-list-item>
          <a-list-item><b>City:</b> {{ item.city }}</a-list-item>
          <a-list-item><b>State:</b> {{ item.state_code }}</a-list-item>
          <a-list-item><b>Postal Code:</b> {{ item.postal_code }}</a-list-item>
        </a-list>
        <a-divider v-if="userDataDrawer.locations.length > 1"></a-divider>
      </div>
    </a-drawer>
  </a-layout-footer>
  <!-- / Layout Footer -->
</template>
<script>
const packageJson = require("../../../package.json");
import firebase from "@/config/db.js";
export default {
  data() {
    return {
      viewMode: "Dark 🌚",
      version: packageJson.version,
      serviceModal: false,
      nerdStats: false,
      geoLocation: "",
      visible: false,
      ip: "",
      userDataDrawer: {
        uid: "",
        admin: "false",
        email: "",
        team_member: "",
        team_id: "",
        team_name: "",
        user_type: "",
        locations: [],
      },
      pStyle: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "rgba(0,0,0,0.85)",
        lineHeight: "24px",
        display: "block",
        marginBottom: "16px",
      },
      pStyle2: {
        marginBottom: "24px",
        marginTop: "12px",
      },
    };
  },
  methods: {
    linkHandle(url) {
      window.open(url, "_blank");
    },
    toggleMode() {
      document.querySelector("cloudflare-app").style.display = "none";
      document.querySelector(".toggler").click();
      this.viewMode == "Light 🌞"
        ? (this.viewMode = "Dark 🌚")
        : (this.viewMode = "Light 🌞");
    },
    toggleServiceModal() {
      this.serviceModal = !this.serviceModal;
    },
    handleOk() {
      this.serviceModal = false;
    },
    toggleNerdStats() {
      this.nerdStats = !this.nerdStats;
    },
    onClose() {
      this.nerdStats = false;
    },
    userDataCollect() {
      const db = firebase.firestore();
      const user = firebase.auth().currentUser;
      this.getUserData();
      this.getIP();
      this.whatGeoLocation();
    },
    getUserData() {
      const db = firebase.firestore();
      const user = firebase.auth().currentUser;
      const that = this;
      db.collection("review_funnel_data")
        .doc("funnel")
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = {
              uid: user.uid,
              admin: false,
              email: user.email,
              team_member: doc.data().is_team_member,
              team_id: doc.data().team_id,
              team_name: "",
              user_type: doc.data().image,
              locations: [],
            };
            that.getTeamData(data);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    getTeamData(data) {
      const db = firebase.firestore();
      const user = firebase.auth().currentUser;
      const that = this;
      db.collection("review_funnel_data")
        .doc("funnel")
        .collection("teams")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let plusTeamData = data;
            plusTeamData.team_name = doc.data().team_name;
            that.getLocations(plusTeamData);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    getLocations(data) {
      const db = firebase.firestore();
      const user = firebase.auth().currentUser;
      const that = this;
      db.collection("review_funnel_data")
        .doc("funnel")
        .collection("locations")
        .doc(user.uid)
        .collection("locations")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let plusLocationData = data;
            plusLocationData.locations.push(doc.data());
            that.userDataDrawer = plusLocationData;
            that.toggleNerdStats();
          });
        });
    },
    whatBrowser() {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) return "Opera " + tem[1];
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    },
    whatDevice() {
      var ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "Tablet";
      }
      if (
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
          ua
        )
      ) {
        return "Mobile";
      }
      return "Desktop";
    },
    whatOS() {
      // is android, windows, linux, mac, etc
      var os = navigator.platform;
      if (os.indexOf("Win") > -1) return "Windows";
      if (os.indexOf("Mac") > -1) return "Mac";
      if (os.indexOf("X11") > -1) return "Unix";
      if (os.indexOf("Linux") > -1) return "Linux";
      return os;
    },
    whatTimeZone() {
      // what time zone am I in
      var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return tz;
    },
    whatGeoLocation() {
      // get geo locations
      const that = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let geoText =
            "Latitude: " +
            position.coords.latitude +
            " Longitude: " +
            position.coords.longitude;
          that.geoLocation = geoText;
        });
      } else {
        return "Geolocation not supported";
      }
    },
    getIP() {
      var that = this;
      fetch("https://api.ipify.org?format=json")
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          that.ip = myJson.ip;
        });
    },
  },
};
</script>
